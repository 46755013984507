<template>
  <div>
    <!-- <NuxtLoadingIndicator color="black" /> -->
    <vPreNav />
    <vNav />
    <main>
      <slot />
    </main>
    <vSvgSprite />
  </div>
</template>

<style scoped>
nav {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  max-width: 512px;
  margin: 0 auto;
}

img {
  width: 110px;
  height: auto;
  vertical-align: middle;
}
</style>
